import {QuestionLabel, Icons, Multidetention} from '@amo/core/components/containers';
import { Text } from '@amo/core/components/forms';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import BlocFranchises from './BlocFranchises/BlocFranchises';
import BlocOption from './BlocOption/BlocOption';
import TableFormule from "./TableFormule/TableFormule.jsx";
import ModalGarantiesVehicule from './ModalGarantiesVehicule/ModalGarantiesVehicule.jsx';

const Tarif = (props) => {
    const {
        radioGroupSelectedASS,
        radioGroupSelectedPCC,
        radioGroupSelectedACCESSEQP,
        radioGroupSelectedKSM,
        radioGroupSelectedVALMAJ,
        listeFormules,
        formulePreconisee,
        formuleSelectionnee,
        fractionnement,
        changeValue,
        franchises,
        garantiesSelected,
        garantiesASS,
        garantiesPCC,
        garantiesKSM,
        garantiesVALMAJ,
        garantiesACCESSEQP,
        reloadTarif,
        codePromo,
        codePromoInForm,
        nbMoisAssuranceMoto,
        nbMoisAssuranceAuto,
        valeurCrmMoto,
        valeurCrmAuto,
        infoDTA,
        infoVI,
        selectedFranchiseVI,
        selectedFranchiseDTA,
        handleSubmit,
        previousStep,
        tarifError,
        setDevisOrSubscribe,
        nextStep,
        typeVehicule,
        vehicule,
        data
    } = props;

    // État pour contrôler l'ouverture/fermeture du modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    const categories = ['CUS','TRA','RTS','GRT','RBA','ROA','SPO'];

    const changeGarantiesSelected = () => {
        let options = {};
        let optionsFranchise = {};
        let optionsListe = {};

        _.map(garantiesSelected, (g) => {
            const id = g.IdGroupeGarantie;

            if (id === 'RAFRAVI' || id === 'RAFRADTA') {
                optionsFranchise[g.CodeGarantie] = true;
            } else if (g.listeTarifOption !== undefined && g.DetailOption !== undefined && id === 'ACCESSEQP') {
                optionsListe[id] = g.DetailOption;
            } else {
                options[id] = g.CodeGarantie;
            }
            changeValue(`options`, options);
            changeValue(`optionsFranchise`, optionsFranchise);
            changeValue(`optionsListe`, optionsListe);
        });
    };

    useEffect(() => {
        //On vérifie si on a une formule sélectionnée et si celle ci est toujours dispo
        if (
            formulePreconisee &&
            (!formuleSelectionnee || !_.find(listeFormules, { CodeFormule: formuleSelectionnee }))
        ) {
            // si on a qu'une seule formule
            if (listeFormules.length === 1) {
                changeValue('DemandeTarif[Police][FormuleChoisie]', listeFormules[0].CodeFormule);
            } else {
                changeValue('DemandeTarif[Police][FormuleChoisie]', formulePreconisee);
            }
        }
    }, [formulePreconisee]);

    useEffect(() => {
        if (garantiesSelected) {
            changeGarantiesSelected();
        }
    }, [garantiesSelected]);

    useEffect(() => {
        changeValue('DemandeDevis[TypeMER]', 'DemandeDevis');
        if (nbMoisAssuranceAuto === undefined && valeurCrmAuto !== undefined) {
            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmAuto]', '1.00');
        }
        if (nbMoisAssuranceMoto === undefined && valeurCrmMoto !== undefined) {
            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmMoto]', '1.00');
        }
    }, []);

    const goDevisOrSubscribe = (form, state) => {
        setDevisOrSubscribe({ state });
        nextStep(form);
    };

    // Fonction pour ouvrir le modal des garanties
    const openGarantiesModal = () => {
        setIsModalOpen(true);
    };

    return (
        <div>
            {/* Bouton pour ouvrir le modal des garanties */}
            <div className="text-center mb-4">
                <button
                    className="btn btn-show-garanties medium-rounded"
                    onClick={openGarantiesModal}
                >
                    Voir toutes les garanties et options
                </button>
            </div>

            <TableFormule
                listeFormules={listeFormules}
                formulePreconisee={formulePreconisee}
                fractionnement={fractionnement}
                formuleSelectionnee={formuleSelectionnee}
            />

            {franchises.labelGroupe ? (
                <>
                    <h1 className={'h4 text-center mt-4 mb-3'}>Voici le détail du montant de vos franchises</h1>
                    <div className={'mb-3 mt-mb-4'}>
                        <BlocFranchises
                            franchises={franchises}
                            fractionnement={fractionnement}
                            infoDTA={infoDTA}
                            infoVI={infoVI}
                            selectedFranchiseVI={selectedFranchiseVI}
                            selectedFranchiseDTA={selectedFranchiseDTA}
                        />
                    </div>
                </>
            ) : infoVI[0] && infoDTA[0] ? (
                (infoVI[0].Disponible !== 'NonDisponible' || infoDTA[0].Disponible !== 'NonDisponible') && (
                    <>
                        <h1 className={'h4 text-center mt-4 mb-3'}>Voici le détail du montant de vos franchises</h1>

                        <div className={'tarifOption border rounded h-100 position-relative mb-footer'}>
                            <div className={'p-4 d-flex align-items-center bg-grey-light text-initial'}>
                                {infoVI[0] && infoVI[0].Disponible !== 'NonDisponible' && (
                                    <p>
                                        <strong>Franchise Vol/Incendie :</strong> {infoVI[0].ConditionsIndemnisation}
                                    </p>
                                )}
                                {infoDTA[0] && infoDTA[0].Disponible !== 'NonDisponible' && (
                                    <p>
                                        <strong>Franchise DTA :</strong> {infoDTA[0].ConditionsIndemnisation}
                                    </p>
                                )}
                            </div>
                        </div>
                    </>
                )
            ) : (
                ''
            )}

            <h1 className={'h4 text-center mt-4 mb-3'}>
                Nous vous conseillons d'améliorer votre couverture
                <br />
                avec des options sur-mesure&nbsp;
            </h1>

            <div className={'row'}>
                {garantiesASS.labelGroupe && (
                    <BlocOption
                        option={garantiesASS}
                        icon={'truck'}
                        selected={radioGroupSelectedASS}
                        fractionnement={fractionnement}
                    />
                )}
                {garantiesPCC.labelGroupe && (
                    <BlocOption
                        option={garantiesPCC}
                        icon={'bicker'}
                        selected={radioGroupSelectedPCC}
                        fractionnement={fractionnement}
                    />
                )}

                {garantiesACCESSEQP.labelGroupe && (
                    <BlocOption
                        option={garantiesACCESSEQP}
                        icon={'garanty'}
                        selected={radioGroupSelectedACCESSEQP}
                        fractionnement={fractionnement}
                    />
                )}

                {garantiesKSM.labelGroupe && (
                    <BlocOption
                        option={garantiesKSM}
                        icon={'tool'}
                        selected={radioGroupSelectedKSM}
                        fractionnement={fractionnement}
                    />
                )}
                {garantiesVALMAJ.labelGroupe && (
                    <BlocOption
                        option={garantiesVALMAJ}
                        icon={'money'}
                        selected={radioGroupSelectedVALMAJ}
                        colLg={''}
                        fractionnement={fractionnement}
                    />
                )}

                <QuestionLabel className={'col-12 f-14 text-center'}>Avez vous un code promotionnel?</QuestionLabel>

                <div className={'col-12 offset-md-2 col-md-4 my-3'}>
                    <Field component={Text} name={'DemandeTarif[CodePromo][CodePromo]'} />
                </div>

                <div className={'col-12 col-md-4 my-3'}>
                    <button
                        className={`${!codePromoInForm && 'disabled'} btn btn-primary medium-rounded w-100`}
                        onClick={!codePromoInForm ? null : reloadTarif}
                    >
                        Valider
                    </button>
                </div>
            </div>

            {codePromo && codePromo.Eligible && codePromo.MessageEligible && (
                <div className={'font-weight-bold text-success text-center'}>
                    <Icons
                        className={`p-2 checkSuccess border full-rounded border-success d-inline-block position-relative align-middle mr-2`}
                        fontSize={7}
                        icon={'Shape'}
                    />
                    {codePromo.MessageEligible}
                </div>
            )}
            {codePromo && !codePromo.Eligible && codePromo.MessageEligible && (
                <div className={'font-weight-bold text-error text-center'}>{codePromo.MessageEligible}</div>
            )}
            {typeVehicule === 'moto' && _.includes(categories, vehicule.codecategorie) && (
                <div id={'souscrire'} className={'container '}>
                    <div className={`row mt-4`}>
                        <div className={`rounded text-center col-12 multidetention`}>
                            {(_.get(data, 'DemandeTarif[Vehicule][EligibleMultidetention]') === 'true' || _.get(data, 'DemandeTarif[Vehicule][EligibleMultidetention]') === true) && (
                                <Multidetention {...props} courtier={true}/>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {!tarifError && (
                <div className={'mt-5 submit-tarif text-center'}>
                    <button
                        className={`btn btn-outline-primary btn-link f-14 mx-4`}
                        onClick={handleSubmit((form) => goDevisOrSubscribe(form, 'devis'))}
                        data-id="btn-devis"
                    >
                        Recevoir le devis par email
                    </button>
                    <button
                        className={`btn btn-primary medium-rounded text-uppercase pl-5 pr-5`}
                        onClick={handleSubmit((form) => goDevisOrSubscribe(form, 'subscribe'))}
                    >
                        Souscrire
                    </button>
                </div>
            )}

            <div className={'submit-row mt-5'}>
                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>

            {/* Modal pour afficher toutes les garanties */}
            <ModalGarantiesVehicule
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                {...props}
            />
        </div>
    );
};

export default Tarif;
