import PropTypes from 'prop-types'
import _ from "lodash";
import { normalizeEuro } from '@amo/core/utils';


const OptionPrix = (props) => {
    const {valueSelected, values, fractionnement} = props
    let value;

    if(values.length === 1){
        value = _.head(values);
    } else if(valueSelected){
        value = _.find(values,  ['value', valueSelected]);
    } else {
        value = _.minBy(values, 'dataAffichage.prix');
    }

    const enPromo = value?.dataAffichage?.prix_promo !== undefined && value?.dataAffichage?.prix > value?.dataAffichage?.prix_promo;

    if (!value) {
        console.error('OptionPrix: value is not defined');
        return null;
    }

    return (
        <>
            {enPromo && <span className={'d-block'}>{normalizeEuro(value.dataAffichage.prix_promo)} /{fractionnement === 'M' ? 'mois': 'an'}</span>}

            <span className={`${enPromo && 'text-decoration-line-through text-dark'}`} style={{fontSize: 16}}>
                {!valueSelected && values.length > 1 && "à partir de "}{ normalizeEuro(value?.dataAffichage?.prix) } /{fractionnement === 'M' ? 'mois': 'an'}
            </span>
        </>
    );
};


OptionPrix.defaultProps = {
    valueSelected: null
};


OptionPrix.propTypes = {
    valueSelected: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    values: PropTypes.array.isRequired
};


export default OptionPrix;
