import { Icons } from '@amo/core/components/containers';
import { normalizeEuro } from '@amo/core/utils';
import _ from 'lodash';
import { useState } from 'react';
import './tableau-garanties.scss';

/**
 * TableauGaranties - Component for displaying insurance guarantees table and options
 * with integrated total price display and payment frequency options
 */
const TableauGaranties = (props) => {
    const {
        garantiesASS,
        garantiesPCC,
        garantiesACCESSEQP,
        garantiesKSM,
        garantiesVALMAJ,
        fractionnement,
        radioGroupSelectedASS,
        radioGroupSelectedPCC,
        radioGroupSelectedACCESSEQP,
        radioGroupSelectedKSM,
        radioGroupSelectedVALMAJ,
        changeValue,
        listeFormules,
        formuleSelectionnee,
        // Franchises related props
        franchises,
        infoDTA,
        infoVI,
        selectedFranchiseVI,
        selectedFranchiseDTA,
        // Tarif total props
        prixTotal,
        prixTotalPromo,
        prixTotalAnnuel,
        prixTotalAnnuelPromo,
        nbOptions,
        marque,
        formulePreconisee,
    } = props;

    // State to track whether guarantees table should be shown or hidden
    const [showGaranties, setShowGaranties] = useState(false);

    /**
     * Groups all guarantees into a single array for rendering
     * @type {Array}
     */
    const toutesGaranties = [
        {
            id: 'ASS',
            data: garantiesASS,
            selected: radioGroupSelectedASS,
            fieldName: 'options[ASS]',
            stateName: 'radioGroupSelectedASS',
            label: 'Assistance',
        },
        {
            id: 'PCC',
            data: garantiesPCC,
            selected: radioGroupSelectedPCC,
            fieldName: 'options[PCC]',
            stateName: 'radioGroupSelectedPCC',
            label: 'Protection corporelle du conducteur',
        },
        {
            id: 'ACCESSEQP',
            data: garantiesACCESSEQP,
            selected: radioGroupSelectedACCESSEQP,
            fieldName: 'optionsListe[ACCESSEQP]',
            stateName: 'radioGroupSelectedACCESSEQP',
            label: 'Accessoires et équipements',
        },
        {
            id: 'KSM',
            data: garantiesKSM,
            selected: radioGroupSelectedKSM,
            fieldName: 'options[KSM]',
            stateName: 'radioGroupSelectedKSM',
            label: 'Protection juridique',
        },
        {
            id: 'VALMAJ',
            data: garantiesVALMAJ,
            selected: radioGroupSelectedVALMAJ,
            fieldName: 'options[VALMAJ]',
            stateName: 'radioGroupSelectedVALMAJ',
            label: 'Valeur majorée',
        },
    ].filter((garantie) => garantie.data && garantie.data.labelGroupe);

    /**
     * Calculates the price for a given option
     *
     * @param {Object} option - The guarantee option
     * @param {string} selected - The selected option value
     * @returns {Object} Object containing price information
     */
    const getPrixOption = (option, selected) => {
        // Get the appropriate values array based on option type
        const values = option.data[0].listeTarifOption && option.idGroupe === 'ACCESSEQP' ? option.data[0].listeTarifOption : option.data;

        // Determine which value to use
        let value;
        if (values.length === 1) {
            value = _.head(values);
        } else if (selected) {
            value = _.find(values, ['value', selected]);
        } else {
            value = _.minBy(values, 'dataAffichage.prix');
        }

        if (!value || !value.dataAffichage) return { prix: 0, prix_promo: 0 };

        // Check if there's a promotional price
        const enPromo = value.dataAffichage.prix_promo !== undefined && value.dataAffichage.prix > value.dataAffichage.prix_promo;

        return {
            prix: value.dataAffichage.prix,
            prix_promo: enPromo ? value.dataAffichage.prix_promo : null,
            enPromo,
        };
    };

    /**
     * Handles option selection/deselection
     *
     * @param {Object} garantie - The guarantee object
     * @param {string} optionValue - The value of the clicked option
     */
    const handleOptionClick = (garantie, optionValue) => {
        // Toggle selection: deselect if clicked on already selected option
        if (garantie.selected === optionValue) {
            changeValue(garantie.fieldName, '');
        } else {
            changeValue(garantie.fieldName, optionValue);
        }
    };

    /**
     * Handles franchise option selection/deselection
     *
     * @param {Object} franchise - The franchise option
     * @param {string} value - The franchise value
     */
    const handleFranchiseClick = (franchise, value) => {
        const fieldName = `optionsFranchise[${value}]`;

        // Determine current selection state
        const isCurrentlySelected = (value === 'RAFRAVI' && selectedFranchiseVI) || (value === 'RAFRADTA' && selectedFranchiseDTA);

        // Toggle selection (set to empty string to deselect, or to value to select)
        if (isCurrentlySelected) {
            changeValue(fieldName, '');
        } else {
            changeValue(fieldName, value);
        }
    };

    /**
     * Formats price for display
     *
     * @param {number} price - The price to format
     * @param {boolean} isMonthly - Whether to display as monthly price
     * @returns {string} Formatted price string
     */
    const formatPrice = (price, isMonthly = fractionnement === 'M') => {
        return `${normalizeEuro(price)} ${isMonthly ? '/mois' : '/an'}`;
    };

    /**
     * Calculates price difference for option comparison
     *
     * @param {Object} garantie - The guarantee object
     * @param {string} optionValue - The value of the option to compare
     * @returns {Object} Price difference information
     */
    const getPriceDifference = (garantie, optionValue) => {
        // Get current selected option price (if any)
        const currentPriceInfo = garantie.selected ? getPrixOption(garantie.data, garantie.selected) : null;
        const currentPrice = currentPriceInfo ? (currentPriceInfo.enPromo ? currentPriceInfo.prix_promo : currentPriceInfo.prix) : 0;

        // Get compared option price
        const optionPriceInfo = getPrixOption(garantie.data, optionValue);
        const optionPrice = optionPriceInfo.enPromo ? optionPriceInfo.prix_promo : optionPriceInfo.prix;

        // Determine difference based on context
        let difference;
        if (garantie.selected === optionValue) {
            // This is the currently selected option - show removal cost
            difference = -optionPrice;
        } else if (garantie.selected) {
            // Switching from one option to another - show difference
            difference = optionPrice - currentPrice;
        } else {
            // Adding a new option - show addition cost
            difference = optionPrice;
        }

        // Return formatted difference with sign
        return {
            difference,
            formatted: difference === 0 ? '' : `${difference > 0 ? '+' : '-'}${normalizeEuro(Math.abs(difference))}`,
            isPositive: difference > 0,
            isNegative: difference < 0,
        };
    };

    /**
     * Gets information about all available formulas
     *
     * @returns {Array} Array of formula objects with display information
     */
    const getFormulaInfo = () => {
        return _.map(listeFormules, (formule) => ({
            code: formule.CodeFormule,
            libelle: formule.LibelleFormule,
            prixMensuel: formule.PrimeMensuelleBase,
            prixAnnuel: formule.PrimeAnnuelleFracAnnuelBase,
            isSelected: formule.CodeFormule === formuleSelectionnee,
            formulePreconisee: formule.CodeFormule === formulePreconisee,
        }));
    };

    /**
     * Handle payment frequency change
     *
     * @param {string} value - New payment frequency value ('M' for monthly, 'A' for annual)
     */
    const handleFractionnement = (value) => {
        changeValue('DemandeTarif[Police][FractionnementChoisi]', value);
    };

    const formulas = getFormulaInfo();
    // Get the selected formula for the price display
    const selectedFormula = _.find(formulas, { isSelected: true });

    /**
     * Renders an option card
     *
     * @param {Object} garantie - The guarantee object
     * @param {Object} option - The option to render
     * @param {number} idx - The index for key generation
     * @returns {JSX.Element} The option card element
     */
    const renderOptionCard = (garantie, option, idx) => {
        const prixInfo = getPrixOption(garantie.data, option.value);
        const isSelected = garantie.selected === option.value;
        const priceDiff = getPriceDifference(garantie, option.value);

        // Determine action type for styling
        const isRemoveAction = isSelected;
        const isAddAction = !isSelected && garantie.selected;
        const isNewSelection = !isSelected && !garantie.selected;

        // Determine CSS class
        const actionClass = isRemoveAction ? 'option-remove' : isAddAction || isNewSelection ? 'option-add' : '';

        // Determine action text
        const actionText = isRemoveAction || !priceDiff.isPositive ? `Enlever (${priceDiff.formatted})` : `Ajouter (${priceDiff.formatted})`;

        return (
            <div key={`${garantie.id}-${idx}`} className={`option-card ${isSelected ? 'option-selected' : ''} ${actionClass}`} onClick={() => handleOptionClick(garantie, option.value)}>
                <div className="option-price">{formatPrice(prixInfo.enPromo ? prixInfo.prix_promo : prixInfo.prix)}</div>
                <div className="option-label">{option.label}</div>

                {/* Price comparison indicator - only shown if there's a difference */}
                {priceDiff.difference !== 0 && <div className={`price-comparison ${priceDiff.isPositive ? 'price-positive' : 'price-negative'}`}>{actionText}</div>}
            </div>
        );
    };

    /**
     * Renders franchise information as cards in the options table
     *
     * @returns {JSX.Element|null} The franchise section or null if not applicable
     */
    const renderFranchisesSection = () => {
        if (franchises && franchises.labelGroupe) {
            return (
                <tr className="option-row franchise-row">
                    <td className="garantie-title px-3 py-1">{franchises.labelGroupe || 'Franchises'}</td>
                    <td className="p-0">
                        <div className="option-choices">
                            {/* Render each franchise option as a card */}
                            {_.map(franchises.data, (franchise, idx) => {
                                // Determine if this franchise is selected
                                const isSelected = (franchise.value === 'RAFRAVI' && selectedFranchiseVI) || (franchise.value === 'RAFRADTA' && selectedFranchiseDTA);

                                // Use similar action class styling as options
                                const actionClass = isSelected ? 'option-remove' : 'option-add';

                                return (
                                    <div
                                        key={`franchise-${idx}`}
                                        className={`option-card ${isSelected ? 'option-selected' : ''} ${actionClass}`}
                                        onClick={() => handleFranchiseClick(franchise, franchise.value)}
                                    >
                                        <div className="option-price">{formatPrice(franchise.dataAffichage.prix || 0)}</div>
                                        <div className="option-label">{franchise.label}</div>

                                        {/* Status indicator - similar to option cards */}
                                        {isSelected ? (
                                            <div className="price-comparison price-negative">Enlever (-{normalizeEuro(franchise.dataAffichage.prix || 0)})</div>
                                        ) : (
                                            <div className="price-comparison price-positive">Ajouter (+{normalizeEuro(franchise.dataAffichage.prix || 0)})</div>
                                        )}

                                        {/* Additional details about the franchise */}
                                        {franchise.dataAffichage.conditionsIndemnisation && (
                                            <div className="franchise-details small mt-2">
                                                {franchise.value === 'RAFRAVI' && (
                                                    <span>{isSelected ? franchise.dataAffichage.conditionsIndemnisation : infoVI?.[0]?.ConditionsIndemnisationSansRachat || ''}</span>
                                                )}
                                                {franchise.value === 'RAFRADTA' && (
                                                    <span>{isSelected ? franchise.dataAffichage.conditionsIndemnisation : infoDTA?.[0]?.ConditionsIndemnisationSansRachat || ''}</span>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </td>
                </tr>
            );
        } else if (infoVI?.[0] && infoDTA?.[0]) {
            if (infoVI[0].Disponible !== 'NonDisponible' || infoDTA[0].Disponible !== 'NonDisponible') {
                return (
                    <tr className="option-row info-row">
                        <td className="garantie-title px-3 py-1">Franchises</td>
                        <td className="p-4">
                            <div className="franchise-info bg-grey-light p-3 rounded">
                                {infoVI[0] && infoVI[0].Disponible !== 'NonDisponible' && (
                                    <p className="mb-2">
                                        <strong>Franchise Vol/Incendie :</strong> {infoVI[0].ConditionsIndemnisation}
                                    </p>
                                )}
                                {infoDTA[0] && infoDTA[0].Disponible !== 'NonDisponible' && (
                                    <p className="mb-0">
                                        <strong>Franchise DTA :</strong> {infoDTA[0].ConditionsIndemnisation}
                                    </p>
                                )}
                            </div>
                        </td>
                    </tr>
                );
            }
        }

        return null;
    };

    return (
        <div className="garanties-container">
            {/* Header avec prix total, boutons de fractionnement et toggle de garanties */}
            <div className="tarif-header">
                <div className="tarif-info">
                    <div className="formule-title">Formule {selectedFormula?.libelle}</div>
                    <div className="tarif-bloc">
                        <div className="votre-tarif">Votre tarif :</div>
                        <div className="prix-principal">
                            {normalizeEuro(prixTotalPromo || prixTotal || 0)} TTC
                            <span className="frequency">/{fractionnement === 'M' ? 'mois' : 'an'}</span>
                        </div>
                        {fractionnement === 'M' ? (
                            <div className="prix-secondaire">soit {normalizeEuro(prixTotalAnnuelPromo || prixTotalAnnuel || 0)} TTC/an</div>
                        ) : (
                            fractionnement === 'A' && <div className="prix-secondaire">soit {normalizeEuro((prixTotalPromo || prixTotal) / 12)} TTC/mois</div>
                        )}
                    </div>
                </div>

                <div className="controls-bloc">
                    <div className="fractionnement-buttons">
                        <button className={`btn-mensuel ${fractionnement === 'M' ? 'active' : ''}`} onClick={() => handleFractionnement('M')}>
                            Mensuel
                        </button>
                        <button className={`btn-annuel ${fractionnement === 'A' ? 'active' : ''}`} onClick={() => handleFractionnement('A')}>
                            Annuel
                            <span className="economy">(économisez jusqu'à 10%)</span>
                        </button>
                    </div>

                    <div className="garanties-toggle">
                        <button className="btn-toggle-garanties" onClick={() => setShowGaranties(!showGaranties)}>
                            {showGaranties ? 'Masquer les garanties' : 'Afficher les garanties'}
                        </button>
                    </div>
                </div>
            </div>

            {/* Tableau des garanties incluses */}
            <div className="table-responsive">
                <table className="table table-garanties">
                    <thead>
                        <tr>
                            {showGaranties && <th className="garantie-header"></th>}
                            {formulas.map((formula) => (
                                <th
                                    key={formula.code}
                                    className={`formule-header ${formula.isSelected ? 'formule-selected' : ''}`}
                                    onClick={() => changeValue('DemandeTarif[Police][FormuleChoisie]', formula.code)}
                                >
                                    <div className="formule-header-container">
                                        {formula.formulePreconisee && <span className="formule-recommander">Recommandée</span>}

                                        <div className="formule-title">{formula.libelle}</div>
                                        <div className="formule-price">{fractionnement === 'M' ? `${normalizeEuro(formula.prixMensuel)} TTC/mois` : `${normalizeEuro(formula.prixAnnuel)} TTC/an`}</div>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    {/* Conditionnellement afficher le corps du tableau des garanties */}
                    {showGaranties && (
                        <tbody>
                            {props.allGaranties &&
                                _.map(props.allGaranties, (garantie, index) => (
                                    <tr key={`garantie-${index}`}>
                                        <td className="garantie-name">{garantie.LibelleGarantie}</td>
                                        {formulas.map((formula) => {
                                            const isIncluded = props.isGarantieIncluded(garantie, formula.code);
                                            return (
                                                <td key={`${formula.code}-${index}`} className={`garantie-cell ${formula.isSelected ? 'formule-selected-cell' : ''}`}>
                                                    {isIncluded ? (
                                                        <div className="garantie-included">
                                                            <Icons icon="Shape" fontSize={10} className="icon-check" />
                                                        </div>
                                                    ) : (
                                                        <div className="garantie-not-included">-</div>
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                        </tbody>
                    )}
                </table>
            </div>

            {/* Titre Options et Franchises */}
            <h2 className="options-title">Options et Franchises</h2>

            {/* Options additionnelles et franchises */}
            <table className="table table-options">
                <tbody>
                    {/* Render franchises section first */}
                    {renderFranchisesSection()}

                    {/* Then render regular options */}
                    {toutesGaranties.map((garantie) => {
                        // Get options list based on type
                        const optionsList = garantie.data.data[0].listeTarifOption && garantie.id === 'ACCESSEQP' ? garantie.data.data[0].listeTarifOption : garantie.data.data;

                        // Filter out empty options
                        const realOptions = optionsList.filter((opt) => opt.value !== '');

                        return (
                            <tr key={garantie.id} className="option-row">
                                <td className="garantie-title px-3 py-1">{garantie.data.labelGroupe}</td>
                                <td className="p-0">
                                    <div className="option-choices">
                                        {/* Render each option card */}
                                        {realOptions.map((option, idx) => renderOptionCard(garantie, option, idx))}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TableauGaranties;
