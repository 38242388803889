import {BulleAide, QuestionLabel, SimpleTitle} from '@amo/core/components/containers';
import {Autocomplete, DateField, Radio, Text, Select, Checkbox, AutoCompleteBirth} from '@amo/core/components/forms';
import { crm } from '@amo/core/utils/functions';
import { getCountries } from '@amo/core/utils/requestsApi';
import {isValid, maxDateToday, min14Year, min16Year, min18Year, onError, required} from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import QuestionContentContainer from '../../QuestionContent/QuestionContentContainer';
import GestionPermis from './GestionPermis/GestionPermis.jsx';
import TitulaireCarteGriseMajeur from './TitulaireCarteGriseMajeur/TitulaireCarteGriseMajeur';
import TitulaireCarteGriseMineur from './TitulaireCarteGriseMineur/TitulaireCarteGriseMineur';
import warning from "@amo/core/assets/images/icons/warning.svg";
import {validateLicenseByVehicule} from "@amo/core/utils/index.js";

const Profil = (props) => {
    const {
        birthCity,
        birthCountry,
        birthCountryRepresentantLegal,
        conducteur,
        typePermisAM,
        typePermisA1,
        typePermisA2,
        typePermisA,
        typePermisB,
        permis,
        changeValue,
        unsetValue,
        dateNaissance,
        valeurCrmMoto,
        valeurCrmAuto,
        cylindreeVehicule,
        familleProduit,
        datePermisB,
        minCRMAuto,
        minCRMMoto,
        titulaireCarteGrise,
        permisOnError,
        vehiculeImmatricule,
        conducteurMineur,
        valid,
        validLicence,
        handleSubmit,
        nextStep,
        fillLoading,
        previousStep,
        countries = [],
        setCountries,
        dejaClientRefresh,
        dejaClientData,
        listePersonnes,
        vehicule,
        formation,
        DeclarationAssurance
    } = props;
    let validLicense = validateLicenseByVehicule({ permis, vehicule, dateNaissance });
    const [mineur, setMineur] = useState(
        moment.isMoment(dateNaissance) &&
            moment(dateNaissance).isValid() &&
            moment(dateNaissance).add(18, 'years') > moment(),
    );
    const [validDateNaissance, setValidDateNaissance] = useState(
        moment.isMoment(dateNaissance) && moment(dateNaissance).isValid() ? moment(dateNaissance) : null,
    );
    const [errorLoadingCountries, setErrorLoadingCountries] = useState(false);
    const [capbirthIsDown, setCapBirthIsDown] = useState(false);
    const [dateNaissanceValue, setDateNaissanceValue] = useState(dateNaissance);
    const [titulaireCarteGriseValue, setTitulaireCarteGriseValue] = useState(titulaireCarteGrise);
    const isDejaClient = !_.isEmpty(dejaClientData);

    const hasOnlyPermisB = (permis) => {
        return !(_.find(permis, ['TypePermis', 'A1']) || _.find(permis, ['TypePermis', 'A']) || _.find(permis, ['TypePermis', 'A2'])) && _.find(permis, ['TypePermis', 'B']);
    };

    const isNonEligible = formation === '0' && datePermisB.isSameOrAfter(moment('2011-01-01'));

    const changeAge = (nouvelleDateNaissance) => {
        if (moment.isMoment(nouvelleDateNaissance) && moment(nouvelleDateNaissance).isValid()) {
            if (moment(nouvelleDateNaissance).add(18, 'years') > moment() && !mineur) {
                changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'P');
                changeValue('DemandeTarif[ListePersonnes][1][RoleConducteur]', 'N');
                changeValue('titulaireCarteGrise', null);
                changeValue('DemandeTarif[ListePersonnes][0][ListePermis]', null);
            } else if (mineur) {
                //Si ListePersonnes[0] indiqué mineur mais maintenant majeur
                changeValue('DemandeTarif[ListePersonnes]', [{ ...conducteur }]);
            }

            setValidDateNaissance(nouvelleDateNaissance);
            setMineur(moment(nouvelleDateNaissance).add(18, 'years') > moment());

            //Si on a aucun permis de renseigné et que l'on a pas le choix "je n'ai pas de permis" on affiche une erreur
            changeValue(
                'permisOnError',
                !(cylindreeVehicule <= 50 && moment(nouvelleDateNaissance) < moment('1988-01-01')) &&
                    !(typePermisAM || typePermisA1 || typePermisA2 || typePermisA || typePermisB)
                    ? 'onError'
                    : null,
            );
        }
    };

    useEffect(() => {
        //Si on a aucun permis de renseigné et que l'on a pas le choix "je n'ai pas de permis" on affiche une erreur
        changeValue(
            'permisOnError',
            !(cylindreeVehicule <= 50 && moment(validDateNaissance) < moment('1988-01-01')) &&
                !(typePermisAM || typePermisA1 || typePermisA2 || typePermisA || typePermisB)
                ? 'onError'
                : null,
        );

        if (
            !_.find(permis, function (p) {
                return p && p.TypePermis;
            })
        ) {
            changeValue('DemandeTarif[ListePersonnes][0][ListePermis]', null);
        }

        if (!(typePermisAM || typePermisA1 || typePermisA2 || typePermisA || typePermisB)) {
            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmMoto]', null);
        }
    }, [permis]);

    useEffect(() => {
        if (familleProduit !== '50') {
            changeValue('DemandeTarif[ListePersonnes][0][ConducteurMineur]', false);
        }
    }, [familleProduit]);

    useEffect(() => {
        if (
            moment.isMoment(dateNaissance) &&
            moment(dateNaissance).add(18, 'years') <= moment() &&
            vehiculeImmatricule === '0' &&
            _.includes(['quad', 'verte', '50'], familleProduit)
        ) {
            changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', true);
        }
    }, [dateNaissance]);

    useEffect(() => {
        //Si on a fait un changement de date de naissance, on refresh le déjà client
        if (moment(dateNaissance, 'DD/MM/YYYY', true).isValid() && dateNaissance !== dateNaissanceValue) {
            dejaClientRefresh({updateOnlyPersonFields: true});
            setDateNaissanceValue(dateNaissance);
            //Vérifier si la date de naissance est valide
        }
    }, [dateNaissance]);

    useEffect(() => {
        if(titulaireCarteGriseValue !== titulaireCarteGrise) {
            dejaClientRefresh({updateOnlyPersonFields: true});
            setTitulaireCarteGriseValue(titulaireCarteGrise);
        }
    }, [titulaireCarteGrise]);

    useEffect(() => {
        (async function fetchCountries() {
            let countries = await getCountries();
            setCountries({ countries });
        })();
    }, []);

    return (
        <div>
            <SimpleTitle>Votre profil</SimpleTitle>

            {familleProduit === '50' && (
                <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][ConducteurMineur]'}>
                    <div className={'mt-4 mt-md-4'}>
                        <Field
                            name="DemandeTarif[ListePersonnes][0][ConducteurMineur]"
                            component={Checkbox}
                            type={'checkbox'}
                        >
                            <div className={'f-14 mx-2'}>Le futur conducteur ne dispose pas encore du permis AM ou n'a pas 14 ans.</div>
                        </Field>
                    </div>
                </QuestionContentContainer>
            )}
            {conducteurMineur && (
                <div className={'bg-warning p-4 rounded text-left mt-5 f-14'}>
                    <p className={'mb-0'}>
                        Le contrat sera fait au nom du responsable légal et celui-ci sera déclaré conducteur habituel
                        jusqu'à modification du contrat.
                        <br />
                        <br />
                        <i>
                            Le futur conducteur sera un enfant de plus de 14 ans qui disposera du permis AM et le client
                            s'engage à le déclarer dès qu'il en disposera.
                        </i>
                    </p>
                </div>
            )}

            <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][DateNaissance]'}>
                <QuestionLabel required>Quelle est votre date de naissance&nbsp;?&nbsp;</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={DateField}
                            name={'DemandeTarif[ListePersonnes][0][DateNaissance]'}
                            label={'JJ/MM/AAAA'}
                            validate={[
                                isValid,
                                maxDateToday,
                                cylindreeVehicule <= 50 ? min14Year : cylindreeVehicule <= 125 ? min16Year : min18Year,
                            ]}
                            maxDate={new Date()}
                            onChange={changeAge}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][BirthCountry]'}>
                <QuestionLabel required>Quel est votre pays de naissance&nbsp;?&nbsp;</QuestionLabel>
                <div className="row">
                    <div className="col-12 col-md-4 mt-2 mt-md-2">
                        <Field
                            name={'DemandeTarif[ListePersonnes][0][BirthCountry]'}
                            component={Autocomplete}
                            data={countries.map((c) => ({ label: c.name, value: c.id }))}
                            required
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][BirthCity]'}>
                <QuestionLabel required>Quelle est votre ville de naissance&nbsp;?&nbsp;</QuestionLabel>
                <div className="row">
                    <div className="col-12 col-md-4 mt-2 mt-md-2">
                       {
                            !capbirthIsDown && birthCountry === 'FRA' ?
                                <Field
                                    id={'BirthCity'}
                                    icon={'pin'}
                                    name={'DemandeTarif[ListePersonnes][0][BirthCity]'}
                                    component={AutoCompleteBirth}
                                    setCapBirthIsDown={setCapBirthIsDown}
                                    setErrorLoadingCountries={setErrorLoadingCountries}
                                    validate={required}
                                    country={birthCountry}
                                    onChangeCityFunction={(value, listVilles) => {
                                        changeValue(`DemandeTarif[ListePersonnes][0][BirthDepartment]`, null)
                                        if (value) {
                                            const villeSelected = _.find(listVilles, ['value', value]);
                                            if (villeSelected?.department) {
                                                changeValue(`DemandeTarif[ListePersonnes][0][BirthDepartment]`, villeSelected.department)
                                            }
                                        }
                                    }}
                                />
                                :  <Field
                                    component={Text}
                                    name={'DemandeTarif[ListePersonnes][0][BirthCity]'}
                                    normalize={v => v.toUpperCase()}
                                    validate={[required]}
                                />
                        }
                    </div>
                </div>
            </QuestionContentContainer>

            {mineur ? (
                <>
                    <QuestionContentContainer name={'DemandeTarif[ListePersonnes][1][DateNaissance]'}>
                        <QuestionLabel required>
                            Quelle est la date de naissance du représentant légal&nbsp;?&nbsp;
                        </QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={DateField}
                                    name={'DemandeTarif[ListePersonnes][1][DateNaissance]'}
                                    label={'JJ/MM/AAAA'}
                                    validate={[isValid, maxDateToday, min18Year]}
                                    maxDate={new Date()}
                                    onChange={() => changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'P')}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>

                    <QuestionContentContainer name={'DemandeTarif[ListePersonnes][1][BirthCountry]'}>
                        <QuestionLabel required>
                            Quel est le pays de naissance du représentant légal&nbsp;?&nbsp;
                        </QuestionLabel>
                        <div className="row">
                            <div className="col-12 col-md-4 mt-2 mt-md-2">
                                <Field
                                    name={'DemandeTarif[ListePersonnes][1][BirthCountry]'}
                                    component={Autocomplete}
                                    data={countries.map((c) => ({ label: c.name, value: c.id }))}
                                    required
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>

                    <QuestionContentContainer name={'DemandeTarif[ListePersonnes][1][BirthCity]'}>
                        <QuestionLabel required>
                            Quelle est la ville de naissance du représentant légal&nbsp;?&nbsp;
                        </QuestionLabel>
                        <div className="row">
                            <div className="col-12 col-md-4 mt-2 mt-md-2">

                                {
                                        !capbirthIsDown && birthCountryRepresentantLegal === 'FRA' ?
                                            <Field
                                                id={'BirthCityRepresentantLegal'}
                                                icon={'pin'}
                                                name={'DemandeTarif[ListePersonnes][1][BirthCity]'}
                                                component={AutoCompleteBirth}
                                                setCapBirthIsDown={setCapBirthIsDown}
                                                validate={required}
                                                setErrorLoadingCountries={setErrorLoadingCountries}
                                                country={birthCountryRepresentantLegal}
                                                onChangeCityFunction={(value, listVilles) => {
                                                    changeValue(`DemandeTarif[ListePersonnes][1][BirthDepartment]`, null)
                                                    if (value) {
                                                        const villeSelected = _.find(listVilles, ['id', value]);
                                                        if (villeSelected?.department) {
                                                            changeValue(`DemandeTarif[ListePersonnes][1][BirthDepartment]`, villeSelected.department)
                                                        }
                                                    }
                                                }}
                                            />
                                            :  <Field
                                                component={Text}
                                                name={'DemandeTarif[ListePersonnes][1][BirthCity]'}
                                                normalize={v => v.toUpperCase()}
                                                validate={[required]}
                                            />
                                    }
                            </div>
                        </div>
                    </QuestionContentContainer>
                </>
            ) : (
                ''
            )}

            {vehiculeImmatricule !== '0' && (
                <QuestionContentContainer name={'titulaireCarteGrise'}>
                    <QuestionLabel required>La carte grise est :&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        {!mineur
                            ? <TitulaireCarteGriseMajeur
                                conducteur={conducteur}
                                familleProduit={familleProduit}
                                changeValue={changeValue}
                                isDejaClient={isDejaClient}
                                isSociety={listePersonnes?.[1]?.TypePersonne === 'M'}/>
                            : <TitulaireCarteGriseMineur conducteur={conducteur} changeValue={changeValue}/>
                        }
                    </div>
                    {titulaireCarteGrise === 'onError' && (
                        <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                            <p className={'mb-0'}>Le souscripteur doit être le titulaire de la carte grise.</p>
                        </div>
                    )}
                </QuestionContentContainer>
            )}

            <GestionPermis
                {...props}
                validDateNaissance={validDateNaissance}
                mineur={mineur}
                permisOnError={permisOnError}
            />

            {!mineur && validDateNaissance && !datePermisB && cylindreeVehicule > 125 && _.includes(['moto', 'scooter'], familleProduit) &&
                <BulleAide
                    icon={warning}
                    title={`<b>ATTENTION</b>`}
                    text={`<p>Le permis B n'est pas renseigné, sa date d'obtention est prise en compte dans le calcul de la prime.</p>
                    <p>Merci de le préciser pour bénéficier de notre meilleur tarif.</p>`}
                    className={'col-lg-12 col-md-12 f-14'}
                />
            }

            {(((familleProduit?.includes('moto') || familleProduit?.includes('scooter') || familleProduit?.includes('verte')) && Number(cylindreeVehicule) > 50 && Number(cylindreeVehicule) <= 125 && hasOnlyPermisB(permis)) || (vehicule?.genre === 'TRI' && !_.find(permis, (p) => p?.TypePermis === 'A'))) && (
                <>
                    {(datePermisB >= moment('2011-01-01') || datePermisB >= moment('1980-03-01') && datePermisB < moment('2011-01-01')) &&
                        <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][Formation]'}>
                            <QuestionLabel required>Vous déclarez avoir réalisé la formation de 7h pour conduire ce type de véhicule&nbsp;?</QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={'DemandeTarif[ListePersonnes][0][Formation]'}
                                        value={'1'}
                                        label={'Oui'}
                                        className={'small'}
                                        validate={required}
                                        onChange={() => changeValue('DemandeTarif[ListePersonnes][0][DeclarationAssurance]', null)}
                                    />
                                </div>
                                <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={'DemandeTarif[ListePersonnes][0][Formation]'}
                                        value={'0'}
                                        label={'Non'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>

                            </div>
                            {isNonEligible && (
                                <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                                    <b>Vous pouvez conduire une moto 125cm³ avec un permis B si vous remplissez les 2 conditions
                                        suivantes :</b><br />
                                    - Vous avez le permis B depuis au moins 2 ans<br />
                                    - Vous avez suivi une formation pratique de 7 heures<br /><br />
                                    <b>Vous pouvez conduire un scooter 3 roues de la catégorie L5e avec un permis B si vous remplissez
                                        les 3 conditions suivantes :</b><br />
                                    - Vous avez le permis B depuis au moins 2 ans<br />
                                    - Vous avez 21 ans ou plus<br />
                                    - Vous avez suivi une formation pratique de 7 heures<br /><br />
                                    <b>Vous êtes dispensé de suivre la formation dans les 3 cas suivants :</b><br />
                                    - Vous avez obtenu votre permis B (avec l'équivalence A1) avant mars 1980<br />
                                    - Vous avez conduit une 125cm³ ou un scooter 3 roues de la catégorie L5e entre 2006 et 2010<br />
                                    - Si vous avez suivi la formation de 3 heures avant 2011
                                </div>
                            )}
                        </QuestionContentContainer>
                    }
                    {(datePermisB >= moment('1980-03-01') && datePermisB < moment('2011-01-01') && formation === '0') &&
                        <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][DeclarationAssurance]'}>
                            <QuestionLabel required>Vous déclarez avoir été assuré de manière permanente ou non pour un 2 roues 125cm³ ou un 3 roues de la catégorie L5e entre 2006 et 2010&nbsp;?</QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={'DemandeTarif[ListePersonnes][0][DeclarationAssurance]'}
                                        value={'1'}
                                        label={'Oui'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>
                                <div className={'col-12 col-md-4 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        type={'radio'}
                                        name={'DemandeTarif[ListePersonnes][0][DeclarationAssurance]'}
                                        value={'0'}
                                        label={'Non'}
                                        validate={onError}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>

                            </div>
                            {DeclarationAssurance==='0' && (
                                <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                                    <b>Vous pouvez conduire une moto 125cm³ avec un permis B si vous remplissez les 2 conditions
                                        suivantes :</b><br />
                                    - Vous avez le permis B depuis au moins 2 ans<br />
                                    - Vous avez suivi une formation pratique de 7 heures<br /><br />
                                    <b>Vous pouvez conduire un scooter 3 roues de la catégorie L5e avec un permis B si vous remplissez
                                        les 3 conditions suivantes :</b><br />
                                    - Vous avez le permis B depuis au moins 2 ans<br />
                                    - Vous avez 21 ans ou plus<br />
                                    - Vous avez suivi une formation pratique de 7 heures<br /><br />
                                    <b>Vous êtes dispensé de suivre la formation dans les 3 cas suivants :</b><br />
                                    - Vous avez obtenu votre permis B (avec l'équivalence A1) avant mars 1980<br />
                                    - Vous avez conduit une 125cm³ ou un scooter 3 roues de la catégorie L5e entre 2006 et 2010<br />
                                    - Si vous avez suivi la formation de 3 heures avant 2011
                                </div>
                            )}
                        </QuestionContentContainer>
                    }
                </>
            )}

            {!_.includes(['quad', 'verte', '50'], familleProduit) &&
            (typePermisAM || typePermisA1 || typePermisA2 || typePermisA || typePermisB) ? (
                <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'}>
                    <QuestionLabel required>Quel est votre bonus 2-roues actuel&nbsp;?&nbsp;</QuestionLabel>
                    <div className={'row'}>
                    <div className={'col-8 col-md-8 mt-4 mt-md-4'}>
                            <Field
                                component={Select}
                                name={`DemandeTarif[ListePersonnes][0][ValeurCrmMoto]`}
                                onChange={() => changeValue('DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]', null)}
                                validate={required}
                            >
                                <option value="">Quel est votre bonus-malus 2-roues ?</option>
                                {crm.map((c, index) => (
                                    <option key={index} value={c.code}>
                                        {c.libelle}
                                    </option>
                                ))}
                            </Field>
                        </div>
                    </div>

                    {((valeurCrmMoto < minCRMMoto) && valeurCrmMoto !== null) && (
                        <p className={'m-0 text-error text-danger f-13'}>
                            Attention, le bonus saisi est inférieur au bonus calculé automatiquement par rapport à
                            vos dates de permis (valeur {minCRMMoto}). Merci de bien vérifier cette information
                            avant de valider cette étape.
                        </p>
                    )}
                </QuestionContentContainer>
            ) : (
                ''
            )}

            {Number(valeurCrmMoto) === 0.5 && (
                <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]'}>
                    <QuestionLabel required>
                        Avez vous ce bonus moto 0.50 depuis plus de 3 ans&nbsp;?&nbsp;
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]'}
                                value={'1'}
                                type={'radio'}
                                label={'Oui'}
                                validate={required}
                                className={'small'}
                            />
                        </div>

                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]'}
                                value={'0'}
                                type={'radio'}
                                label={'Non'}
                                validate={required}
                                className={'small'}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            {typePermisB && !_.includes(['quad', 'verte', '50'], familleProduit) && (
                <>
                    <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]'}>
                        <QuestionLabel required>Quel est votre bonus auto actuel&nbsp;?&nbsp;</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-8 col-md-8 mt-4 mt-md-4'}>
                                <Field
                                    component={Select}
                                    name={`DemandeTarif[ListePersonnes][0][ValeurCrmAuto]`}
                                    onChange={() =>
                                        changeValue('DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]', null)
                                    }
                                    validate={required}
                                >
                                    <option value="">Quel est votre bonus-malus auto ?</option>
                                    {crm.map((c, index) => (
                                        <option key={index} value={c.code}>
                                            {c.libelle}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>
                        {((valeurCrmAuto < minCRMAuto) && valeurCrmAuto !== null) && (
                            <p className={'m-0 text-error text-danger f-13'}>
                                Attention, le bonus saisi est inférieur au bonus calculé automatiquement par rapport
                                à vos dates de permis (valeur {minCRMAuto}). Merci de bien vérifier cette
                                information avant de valider cette étape.
                            </p>
                        )}
                    </QuestionContentContainer>
                    {Number(valeurCrmAuto) === 0.5 && (
                        <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]'}>
                            <QuestionLabel required>
                                Avez vous ce bonus auto 0.50 depuis plus de 3 ans&nbsp;?&nbsp;
                            </QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]'}
                                        value={'1'}
                                        type={'radio'}
                                        label={'Oui'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>

                                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]'}
                                        value={'0'}
                                        type={'radio'}
                                        label={'Non'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>
                            </div>
                        </QuestionContentContainer>
                    )}
                </>
            )}
            <div className={'submit-row mt-5'}>
                <button
                    className={`${
                        (!valid || validLicense?.status === false || isNonEligible || DeclarationAssurance === '0') && 'disabled'
                    } btn btn-primary medium-rounded float-right mb-2 order-lg-1`}
                    onClick={
                        valid &&
                        (validLicense === undefined || validLicense.status) &&
                        !isNonEligible &&
                        DeclarationAssurance !== 0
                            ? handleSubmit(nextStep)
                            : null
                    }
                    data-id="btn-next"
                >
                    {fillLoading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                    ) : (
                        'Continuer'
                    )}
                </button>


                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>
            <div className="row">
                <p className={'mt-4 text-grey'}>
                    <small>Merci de vérifier si tous les champs du formulaire ont bien été saisis.</small>
                </p>
            </div>
        </div>
    );
};

Profil.defaultProps = {};

Profil.propTypes = {};

export default Profil;
