import _ from 'lodash';
import { useEffect } from 'react';
import TableauGaranties from './TableauGaranties';

/**
 * ModalGarantiesVehicule - Modal component for displaying and selecting vehicle guarantees
 *
 * Displays a full-screen modal with tables for:
 * 1. Included guarantees
 * 2. Available options and franchises for selection
 */
const ModalGarantiesVehicule = (props) => {
    const { isOpen, onClose, listeFormules } = props;

    // Define styles for better readability and maintenance
    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
        },
        content: {
            backgroundColor: 'white',
            width: '100%',
            maxWidth: '1280px',
            height: '99%',
            borderRadius: '8px',
            overflow: 'auto',
            position: 'relative',
            padding: '1rem',
        },
    };

    /**
     * Handle ESC key press to close modal and manage body scroll
     */
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('keydown', handleKeyDown);
            document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
        }

        // Cleanup event listener and restore scrolling
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.body.style.overflow = 'auto';
        };
    }, [isOpen, onClose]);

    // Don't render anything if modal is closed
    if (!isOpen) return null;

    /**
     * Checks if a guarantee is included in a specific formula
     *
     * @param {Object} garantie - The guarantee to check
     * @param {string} codeFormule - The formula code to check against
     * @returns {boolean} Whether the guarantee is included in the formula
     */
    const isGarantieIncluded = (garantie, codeFormule) => {
        const formule = _.find(listeFormules, { CodeFormule: codeFormule });
        return formule && _.find(formule.ListeGaranties, (g) => g.CodeGarantie === garantie.CodeGarantie && g.Disponible === 'Inclusion');
    };

    /**
     * Get all included guarantees from the most complete formula
     * to display in the table
     */
    const allGaranties = _.filter(_.get(_.find(listeFormules, { CodeFormule: _.last(listeFormules)?.CodeFormule }), 'ListeGaranties', []), (g) => g.Disponible === 'Inclusion');

    return (
        <div className="modal-fullscreen-overlay" style={styles.overlay}>
            <div className="modal-fullscreen-content" style={styles.content}>
                {/* Pass all props to TableauGaranties */}
                <div className="pb-2 w-100">
                    <button className="close-button" onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="lucide lucide-x-icon lucide-x">
                            <path d="M18 6 6 18" />
                            <path d="m6 6 12 12" />
                        </svg>
                    </button>
                </div>
                <TableauGaranties {...props} allGaranties={allGaranties} isGarantieIncluded={isGarantieIncluded} />

                <div className="modal-footer p-4">
                    <button className="btn btn-primary" style={styles.button} onClick={()=> {
                        onClose()
                        window.scrollTo(0,document.body.scrollHeight);
                    }}>
                        Valider mes choix
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalGarantiesVehicule;
